<template>
  <div>
    <template v-if="menu.children && menu.children.length">
      <template v-for="cmenu in menu.children">
        <van-collapse-item
          v-if="cmenu.children"
          :title="cmenu.hpdc03"
          :name="cmenu.hpdc01"
          :key="cmenu.hpdc01"
          :border="false"
        >
          <div @click="clicks(cmenu)" style="padding: 20px 40px">
            点击此处查看详情内容...
          </div>
          <SubMenu :key="cmenu.hpdc01" :menu="cmenu" />
        </van-collapse-item>
      </template>
    </template>
  </div>
</template>

<script>
import { Findhelpdoc } from "@/api";
export default {
  name: "SubMenu",
  data() {
    return {
      ContInfo: "",
    };
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clicks(item) {
      let params = {
        helpocid: item.hpdc01,
      };
      Findhelpdoc({ params }).then((res) => {
        if (res.Code > 0) {
          this.ContInfo = res.Data;
          this.$Dialog
            .alert({
              title: this.ContInfo.hpdc03,
              message: this.ContInfo.hpdc06,
            })
            .then(() => {});
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.van-collapse-item__content {
  padding: 0;
}
</style>